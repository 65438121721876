import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { GetAllReferencesModel } from './GetAllReferencesModel'

export const getAllReferences: GetAllReferencesModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.references.getAllReferences,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    GetAllReferencesModel.ResponseData,
    GetAllReferencesModel.Props,
    GetAllReferencesModel.RequestHeaders,
    GetAllReferencesModel.ErrorResponse
  >({
    url: path,
    headers: null,
    query: null,
    responseAdapter: adapter,
    control,
    data: null,
  })

  return response
}

function getAllReferencesKey(props: GetAllReferencesModel.Props) {
  return ['/api/references/getAllReferences', ...Object.values(props)]
}

export function useGetAllReferencesQuery<
  T extends GetAllReferencesModel.ResponseData = GetAllReferencesModel.ResponseData
>(
  props: GetAllReferencesModel.Props = {},
  options: Omit<
    UseQueryOptions<GetAllReferencesModel.ResponseData, GetAllReferencesModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = getAllReferencesKey(props)
  const result = useQuery(key, () => getAllReferences(props).then((res) => res.response), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
