import styled from '@emotion/styled'
import { Headline16, Body16 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'
import { InputDate } from '@platform-ui/components/InputDate'
import { FieldControl } from '@platform-ui/components/FieldControl'

export const Wrapper = styled.div(() => ({
  paddingBottom: '100px',
}))

export const MainTextStyled = styled(Headline16)(() => ({
  marginBottom: '8px',
}))

export const TextStyled = styled(Body16)(() => ({
  lineHeight: '24px',
}))

export const InputDateStyled = styled(InputDate)(() => ({
  marginTop: '24px',
}))

export const AddPeriodButton = styled(Button)(() => ({
  marginTop: '24px',
}))

export const FieldControlStyled = styled<{ isError: boolean }>(FieldControl)(
  ({ isError, theme }) => ({
    div: {
      color: isError ? theme.color['text/brand'] : 'rgb(121, 127, 144)',
    },
  })
)
