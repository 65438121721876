import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  AddPeriodButton,
  FieldControlStyled,
  InputDateStyled,
  MainTextStyled,
  TextStyled,
  Wrapper,
} from './styled'
import { Periods } from '../../containers/AccountsReferences/types'

export interface AccountsReferencesProps {
  periods: Periods
  onAddPeriod: () => void
  onChangePeriod: (key: string, value: string) => void
}

export const AccountsReferences: FC<AccountsReferencesProps> = ({
  periods,
  onAddPeriod,
  onChangePeriod,
}) => {
  const {
    control,
    formState: { isValid },
  } = useFormContext()

  const isAddPeriodButtonHidden = !isValid || periods.some((item) => item.value.length < 10)

  return (
    <Wrapper>
      <MainTextStyled>Справка формируется за предыдущий календарный год.</MainTextStyled>
      <TextStyled>
        Например: если дата 01.03.2024, то справка будет сформирована за период с 01.01.2023 по
        31.12.2023.
      </TextStyled>
      {periods.map((item) => {
        return (
          <Controller
            name={item.id}
            control={control}
            render={({ field, fieldState }) => (
              <FieldControlStyled
                isBlock
                isError={fieldState.invalid}
                error={fieldState.error?.message || 'Укажите дату в формате 01.01.2023'}
              >
                <InputDateStyled
                  {...field}
                  key={item.id}
                  value={item.value}
                  placeholder="ДД.ММ.ГГГГ"
                  isBlock
                  status={fieldState.invalid ? 'error' : 'default'}
                  onClear={() => {
                    onChangePeriod(item.id, '')
                  }}
                  onChange={(e) => {
                    onChangePeriod(item.id, e.currentTarget.value)
                  }}
                />
              </FieldControlStyled>
            )}
          />
        )
      })}
      {!isAddPeriodButtonHidden && (
        <AddPeriodButton onClick={onAddPeriod} view="ghost">
          + Добавить ещё один период
        </AddPeriodButton>
      )}
    </Wrapper>
  )
}
