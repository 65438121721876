import { Dialog } from '@platform-ui/components'
import { AppContext } from '@/models/AppContext'
import { useAppContext } from '@/core'
import { ErrorBoundary } from '@/containers/ErrorBoundary'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import dynamic from '@/next/dynamic'
import { DynamicLoading } from '@/containers/DynamicLoading'

const ReferencesActionResultComponent = dynamic(
  () => import('../../components/ReferencesActionResult'),
  {
    loading: (props) => {
      return <DynamicLoading {...props} />
    },
  }
)

interface ReferencesActionResultProps {
  header: string
  text?: string
  onRetryClick?: () => void
  onClose?: () => void
}

export const ReferencesActionResult: FC = () => {
  const { config, eventBus } = useAppContext<AppContext>()

  const dynamicActive = useRef(false)

  const [isOpen, setIsOpen] = useState(false)

  const [modalProps, setModalProps] = useState<ReferencesActionResultProps>({
    header: '',
    text: '',
  })

  useEffect(() => {
    const openUnsubscribe = eventBus.referencesActionResult.open.on((props) => {
      setIsOpen(true)
      setModalProps({
        header: props.header,
        text: props.text,
        onRetryClick: props.onRetryClick,
        onClose: props.onClose,
      })
    })

    const closeUnsubscribe = eventBus.referencesActionResult.close.on(() => {
      setIsOpen(false)
      setModalProps({
        header: '',
        text: '',
      })
    })

    return () => {
      openUnsubscribe()
      closeUnsubscribe()
    }
  }, [eventBus])

  const handleClose = useCallback(() => {
    eventBus.referencesActionResult.close.emit()
  }, [eventBus])

  /**
   * Препятствует загрузке компонента до его востребования
   */
  if (isOpen) {
    dynamicActive.current = true
  }

  return (
    <ErrorBoundary onCatchError={handleClose}>
      {dynamicActive.current && isOpen && (
        <Dialog
          isOpen={isOpen}
          backgroundColor={'surface/tooltipAndArrested'}
          rounded="r8"
          onOverlayClick={modalProps.onClose}
          onClose={modalProps.onClose}
          {...(!config.device.isMobile && {
            animationOverlayComponent: () => <></>,
          })}
        >
          <ReferencesActionResultComponent
            header={modalProps.header}
            text={modalProps.text}
            onRetryClick={modalProps.onRetryClick}
            onClose={modalProps.onClose}
          />
        </Dialog>
      )}
    </ErrorBoundary>
  )
}
