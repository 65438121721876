import { FC } from 'react'
import {
  IconShimmer,
  ReferenceDateShimmer,
  ReferenceNameShimmer,
  TagShimmer,
  Wrapper,
} from './styled'

export const IssuedReferencesShimmer: FC = () => {
  return (
    <>
      {Array(3).fill(
        <Wrapper>
          <IconShimmer />
          <div>
            <ReferenceNameShimmer />
            <ReferenceDateShimmer />
            <TagShimmer />
          </div>
        </Wrapper>
      )}
    </>
  )
}
