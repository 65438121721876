import React, { FC } from 'react'
import { Icon, ReferenceDate, ReferenceName, Tag, Button, Loader } from './styled'
import { ButtonBaseProps } from '@platform-ui/components/ButtonBase'

type IssuedReferenceItemPlateProps = {
  name: string
  date: string | null
  tagText: string | null
  color: string
  bgColor: string
  loading: boolean
} & ButtonBaseProps

export const IssuedReferenceItemPlate: FC<IssuedReferenceItemPlateProps> = (props) => {
  const { name, date, tagText, color, bgColor, loading, ...restProps } = props
  return (
    <Button {...restProps}>
      {loading ? <Loader /> : <Icon size="s18" />}
      <div>
        <ReferenceName>{name}</ReferenceName>
        {date && <ReferenceDate>{date}</ReferenceDate>}
        {tagText && (
          <Tag color={color} bgColor={bgColor}>
            {tagText}
          </Tag>
        )}
      </div>
    </Button>
  )
}
