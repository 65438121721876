import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const Wrapper = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '23px',
  paddingBottom: '16px',
  marginTop: '16px',
  ':not(:last-child)': {
    borderBottom: '1px solid rgba(56, 59, 79, 0.09)',
  },
}))

export const IconShimmer = styled(Shimmer)(() => ({
  width: '24px',
  height: '29px',
  borderRadius: '5px',
}))

export const ReferenceNameShimmer = styled(Shimmer)(() => ({
  height: '18px',
  maxWidth: '350px',
  marginBottom: '4px',
  borderRadius: '18px',
  minWidth: '250px',
}))

export const ReferenceDateShimmer = styled(Shimmer)(() => ({
  height: '18px',
  width: '70px',
  borderRadius: '18px',
}))

export const TagShimmer = styled(Shimmer)(() => ({
  marginTop: '8px',
  borderRadius: '18px',
  width: '100px',
  height: '20px',
}))
