import React from 'react'
import { observer } from 'mobx-react-lite'
import { IssuedReferences } from '../IssuedReferences'
import { AccountsReferences } from '../AccountsReferences'

type Props = {
  isOpen: boolean
  type: string
  onClose: () => void
}

export const ReferencesModalContent = observer<Props>((props) => {
  const { isOpen, onClose, type } = props

  if (!isOpen) {
    return null
  }

  return (
    <>
      {type === 'issued' && <IssuedReferences onClose={onClose} />}
      {type === 'accounts' && <AccountsReferences onClose={onClose} />}
    </>
  )
})
