import { FC } from 'react'
import { ProductType } from '@/api/products/productDetails/adapters/AdapterModel'
import { GetAllReferencesModel } from '@/api/references/getAllReferences/GetAllReferencesModel'
import { IssuedReferenceItemPlate } from '../../components/IssuedReferenceItemPlate'
import { useGetReferenceUrl } from '@/api/references/getReferenceUrl/getReferenceUrl'
import { ResponseType } from '@/models/ResponseType'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

type IssuedReferenceItemProps = {
  reference: GetAllReferencesModel.Reference
}

export const IssuedReferenceItem: FC<IssuedReferenceItemProps> = ({ reference }) => {
  const { config, eventBus } = useAppContext<AppContext>()
  const { mutateAsync: getReferenceUrl, isLoading } = useGetReferenceUrl()

  const isShoppingCard =
    reference.productType === ProductType.SC || reference.productType === ProductType.SS
  const tagText = !reference.productTag
    ? null
    : isShoppingCard
    ? `${reference.productTag} ₽`
    : reference.productTag

  const getReference = () => {
    getReferenceUrl({
      apiVersion: config.apiVersion,
      refNumber: reference.referenceParams.refNumber,
    })
      .then(({ response }) => {
        if (response.type === ResponseType.ResolveWithData && response.data.scanURL) {
          window.open(response.data.scanURL, '_blank')
        }
      })
      .catch(() => {
        eventBus.referencesActionResult.open.emit({
          header: 'Ошибка при получении справки',
          text: 'Не удалось получить печатную форму справки',
          onRetryClick: () => {
            eventBus.referencesActionResult.close.emit()
            getReference()
          },
          onClose: () => {
            eventBus.referencesActionResult.close.emit()
          },
        })
      })
  }

  return (
    <IssuedReferenceItemPlate
      name={reference.referenceParams.refName}
      date={reference.referenceParams.createDateTime || null}
      tagText={tagText}
      color={reference.tagTextColor}
      bgColor={reference.tagBgColor}
      loading={isLoading}
      onClick={getReference}
    />
  )
}
