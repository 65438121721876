import React from 'react'
import Success from './assets/Success.png'
import { Wrapper, TextStyled, MainTextStyled } from './styled'
import Image from '@/next/image'

export const AccountReferenceOrderSuccess = () => {
  return (
    <Wrapper>
      <Image src={Success} alt="Success" />
      <MainTextStyled>Справка заказана</MainTextStyled>
      <TextStyled>
        Документ будет подготовлен в течение суток. Вы сможете его найти в разделе «Готовые справки»
      </TextStyled>
    </Wrapper>
  )
}
