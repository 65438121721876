import styled from '@emotion/styled'
import { Headline20, Body14 } from '@platform-ui/typography'

export const Wrapper = styled.div(() => ({
  margin: '120px auto 146px',
  maxWidth: '343px',
  textAlign: 'center',
}))

export const MainTextStyled = styled(Headline20)(() => ({
  marginTop: '32px',
}))

export const TextStyled = styled(Body14)(({ theme }) => ({
  color: theme.color['text/secondary'],
  lineHeight: '18px',
  marginTop: '13px',
}))
