import { FC, useState, useMemo } from 'react'
import _uniqueId from 'lodash/uniqueId'
import { FormProvider } from 'react-hook-form'
import { useRouter } from '@/next/router'
import { BaseModal, Tooltip } from '@platform-ui/components'
import { pluralize } from '@/utils/pluralize'
import { useOrderReferences } from '@/api/references/orderReferences/orderReferences'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal'
import { AccountReferenceOrderSuccess } from '../../components/AccountReferenceOrderSuccess'
import { AccountsReferences as AccountsReferencesComponent } from '../../components/AccountsReferences'
import { ContentType } from './types'
import { useFormState } from './useFormState'

export interface AccountsReferencesProps {
  onClose: () => void
}

export const AccountsReferences: FC<AccountsReferencesProps> = ({ onClose }) => {
  const { config, eventBus } = useAppContext<AppContext>()
  const router = useRouter()

  const { mutateAsync: orderReferences, status } = useOrderReferences()

  const [contentType, setContentType] = useState<ContentType>('default')

  const { handleSubmit, addPeriod, changePeriod, form } = useFormState()

  const periods = form.watch('periods')

  const onOrderReference = handleSubmit(() => {
    const cleanPeriods = periods.map((item) => item.value?.replaceAll('.', '-')).filter(Boolean)

    orderReferences({
      apiVersion: config.apiVersion,
      reportDates: cleanPeriods,
    })
      .then(() => {
        setContentType('success')
      })
      .catch(() => {
        setContentType('error')
      })
  })

  const goToChat = () => {
    router.push('/chat')
  }

  const goToIssuedReferences = () => {
    eventBus.referencesModal.open.emit({ type: 'issued' })
  }

  const primaryButtonState: Record<ContentType, { title: string; action: () => void }> =
    useMemo(() => {
      const filledPeriods = periods.filter(
        (item) => !!item.value && !form.formState.errors[item.id]
      )

      return {
        ['error']: {
          title: 'Перейти в чат',
          action: goToChat,
        },
        ['success']: {
          title: 'Перейти в «Готовые справки»',
          action: goToIssuedReferences,
        },
        ['default']: {
          title: `Заказать ${filledPeriods.length > 1 ? filledPeriods.length : ''} ${pluralize(
            filledPeriods.length || 1,
            ['справку', 'справки', 'справок']
          )}`,
          action: onOrderReference,
        },
      }
    }, [periods, form.formState.errors, goToChat, goToIssuedReferences, onOrderReference])

  return (
    <FormProvider {...form}>
      <BaseModal
        isOpen={true}
        onClose={onClose}
        backgroundColor={'surface/main'}
        title={'По счетам и иному имуществу'}
        primaryBtnTitle={primaryButtonState[contentType].title}
        primaryBtnClick={primaryButtonState[contentType].action}
        secondaryBtnTitle={'Закрыть'}
        secondaryBtnClick={onClose}
        isPending={status === 'loading'}
        isBackBtn={contentType === 'error'}
        backBtnClick={() => {
          setContentType('default')
        }}
      >
        {contentType === 'success' && <AccountReferenceOrderSuccess />}

        {contentType === 'error' && (
          <ErrorDataLoadingModal
            title="Операция недоступна"
            description="Повторите попытку позже или напишите в чат"
            isWarning={false}
          />
        )}

        {contentType === 'default' && (
          <AccountsReferencesComponent
            periods={periods}
            onAddPeriod={() => addPeriod('')}
            onChangePeriod={changePeriod}
          />
        )}
      </BaseModal>
    </FormProvider>
  )
}
