import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useGetAllReferencesQuery } from '@/api/references/getAllReferences'
import { IssuedReferencesNotFound } from '../../components/IssuedReferencesNotFound'
import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal'
import { ModalContent } from '../../types'
import { IssuedReferencesShimmer } from '../../components/IssuedReferencesShimmer'
import { IssuedReferenceItem } from '../IssuedReferenceItem'
import { BaseModal } from '@platform-ui/components'
import { ReferencesActionResult } from '../ReferencesActionResult'

export interface IssuedReferencesProps {
  onClose: () => void
}

export const IssuedReferences = observer<IssuedReferencesProps>(({ onClose }) => {
  const [modalContent, setModalContent] = useState<ModalContent | null>({
    title: 'Готовые справки',
    subtitle: 'За последние 180 дней',
  })

  const { data, status, refetch } = useGetAllReferencesQuery()
  const filteredReferences = data?.data?.filter((reference) => !reference.isAutogenRef) || []

  useEffect(() => {
    if (status === 'loading') {
      setModalContent((current) => ({
        ...current,
        buttonText: null,
        onButtonClick: null,
      }))
    }

    if (status === 'error') {
      setModalContent((current) => ({
        ...current,
        buttonText: 'Попробовать ещё раз',
        onButtonClick: refetch,
      }))
    }

    if (status === 'success' && filteredReferences.length !== 0) {
      setModalContent((current) => ({
        ...current,
        buttonText: null,
        onButtonClick: null,
      }))
    }

    if (status === 'success' && filteredReferences.length === 0) {
      setModalContent((current) => ({
        ...current,
        subtitle: null,
      }))
    }
  }, [status, data, setModalContent])

  return (
    <BaseModal
      isOpen={true}
      onClose={onClose}
      backgroundColor={'surface/main'}
      isBackBtn={false}
      title={modalContent.title}
      subtitle={modalContent.subtitle}
      primaryBtnTitle={modalContent.buttonText ?? 'Закрыть'}
      primaryBtnClick={modalContent.onButtonClick ?? onClose}
    >
      {status === 'loading' && <IssuedReferencesShimmer />}

      {status === 'error' && (
        <ErrorDataLoadingModal
          title="Произошла ошибка"
          description="Попробуйте снова или повторите попытку позже"
          isWarning={false}
        />
      )}

      {status === 'success' && filteredReferences.length === 0 && <IssuedReferencesNotFound />}

      {status === 'success' && filteredReferences.length > 0 && (
        <>
          {filteredReferences.map((reference) => (
            <IssuedReferenceItem reference={reference} />
          ))}
        </>
      )}
      <ReferencesActionResult />
    </BaseModal>
  )
})
