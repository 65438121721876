import styled from '@emotion/styled'
import { DocsIcon } from '@platform-ui/icons'
import { ButtonBase, CircularProgress } from '@platform-ui/components'

export const Button = styled(ButtonBase)(() => ({
  background: 'none',
  padding: '0 0 16px 0',
  textAlign: 'start',
  border: 'none',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '16px',
  marginTop: '16px',
  ':not(:last-child)': {
    borderBottom: '1px solid rgba(56, 59, 79, 0.09)',
  },
}))

export const Icon = styled(DocsIcon)(({ theme }) => ({
  color: theme.color['icons/primary'],
  width: '32px',
}))

export const ReferenceName = styled.div(({ theme }) => ({
  ...theme.typography['body/14'],
  color: theme.color['text/main'],
  marginBottom: '4px',
}))

export const ReferenceDate = styled.div(({ theme }) => ({
  ...theme.typography['body/14'],
  color: theme.color['text/secondary'],
}))

export const Tag = styled.div<{ bgColor: string; color: string }>(({ bgColor, color, theme }) => ({
  ...theme.typography['headline/12'],
  padding: '2px 7px',
  marginTop: '8px',
  borderRadius: '18px',
  backgroundColor: bgColor,
  color,
  width: 'min-content',
  whiteSpace: 'nowrap',
}))

export const Loader = styled(CircularProgress)(() => ({
  width: '32px',
  height: '32px',
  marginBottom: '10px',
}))
