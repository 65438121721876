import React from 'react'
import { observer } from 'mobx-react-lite'
import Update from '@/assets/Update.png'
import { Wrapper, TextStyled, MainTextStyled } from './styled'
import Image from '@/next/image'

export const IssuedReferencesNotFound = observer(() => {
  return (
    <Wrapper>
      <Image src={Update} width={108} height={108} alt="Update" />
      <MainTextStyled>Пока справок нет</MainTextStyled>
      <TextStyled>За последние 180 дней вы не заказывали справок</TextStyled>
    </Wrapper>
  )
})
