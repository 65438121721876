import { test, create, each } from 'vest'
import { FormValues } from './types'
import { DATE_REGEXP } from './constants'

export const validationSuite = create((data: FormValues) => {
  each(data.periods, (period) => {
    test(
      period.id,
      'Обязательное поле',
      () => !(data.periods.length === 1 && !period.value),
      `${period.id}1`
    )

    test(
      period.id,
      'Укажите дату периода полностью',
      () => !(period.value.length > 1 && period.value.length < 10),
      `${period.id}2`
    )

    test(
      period.id,
      'Укажите корректную дату в формате 01.01.2023',
      () => period.value && DATE_REGEXP.test(period.value),
      `${period.id}3`
    )
  })
})
