import { useMutation, MutateOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { GetReferenceUrlModel } from './GetReferenceUrlModel'

export const getReferenceUrl: GetReferenceUrlModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.references.getReferenceUrl,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    GetReferenceUrlModel.ResponseData,
    GetReferenceUrlModel.Props,
    GetReferenceUrlModel.RequestData,
    GetReferenceUrlModel.RequestHeaders,
    GetReferenceUrlModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    query: null,
    responseAdapter: adapter,
    control,
    data: {
      RefNumber: props.refNumber,
    },
  })

  return response
}

export function useGetReferenceUrl(
  options?: MutateOptions<
    GetReferenceUrlModel.Response,
    GetReferenceUrlModel.ErrorResponse,
    GetReferenceUrlModel.Props,
    GetReferenceUrlModel.RequestData
  >
) {
  return useMutation(getReferenceUrl, options)
}
