import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { vestResolver } from '@hookform/resolvers/vest'
import { validationSuite } from './validationForm'
import _uniqueId from 'lodash/uniqueId'
import { FormValues } from './types'

const ID_PREFIX = 'periodInput_'

export const useFormState = () => {
  const form = useForm<FormValues>({
    defaultValues: {
      periods: [
        {
          id: _uniqueId(ID_PREFIX),
          value: '',
        },
      ],
    },
    resolver: vestResolver(validationSuite),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  const addPeriod = (value: string) => {
    const current = form.getValues('periods')
    form.setValue('periods', [...current, { id: _uniqueId(ID_PREFIX), value }])
  }

  const changePeriod = (key: string, value: string) => {
    const current = form.getValues('periods')
    const idx = current.findIndex((item) => item.id === key)
    current[idx].value = value
    form.setValue('periods', current)
  }

  useEffect(() => {
    /**
     * Удаление состояния проверки
     */
    return validationSuite.reset
  }, [])

  return {
    handleSubmit: form.handleSubmit,
    addPeriod,
    changePeriod,
    form,
  }
}
