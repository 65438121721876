import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { color } from '@platform-ui/core'
import { formatDate } from '@/utils/formatDate'

const TAG_TEXT_COLOR_MAP: Record<number, string> = {
  1: color['text/success'],
  2: 'rgb(0, 122, 255)',
  3: color['text/primary'],
  4: color['text/attention'],
  5: color['text/main'],
}

const TAG_BG_COLOR_MAP: Record<number, string> = {
  1: color['surface/lightSuccess'],
  2: 'rgba(0, 122, 255, 0.1)',
  3: color['surface/secondary'],
  4: 'rgba(255, 149, 0, 0.1)',
  5: color['surface/tertiary'],
}

const toCurrentFormatDate = (date: string) => {
  if (!date) {
    return date
  }

  const parse = new Date(Date.parse(date))
  return formatDate(parse, 'DD.MM.YYYY')
}

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.StatusCode === 401 || errorData?.StatusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess
    const defaultTagColor = TAG_TEXT_COLOR_MAP[5]
    const defaultBgColor = TAG_BG_COLOR_MAP[5]

    return {
      type: ResponseType.ResolveWithData,
      data: successData.Result.ClientReferenceInfo.map((reference) => ({
        contractStatus: reference.ContractStatus,
        tagTextColor: TAG_TEXT_COLOR_MAP[reference.Colour] || defaultTagColor,
        tagBgColor: TAG_BG_COLOR_MAP[reference.Colour] || defaultBgColor,
        displayOrder: reference.DisplayOrder,
        referenceParams: {
          refCode: reference.ReferenceParams.RefCode,
          isDebit: reference.ReferenceParams.IsDebit,
          refName: reference.ReferenceParams.RefName,
          refProductName: reference.ReferenceParams.RefProductName,
          contractNumber: reference.ReferenceParams.ContractNumber,
          createDateTime: toCurrentFormatDate(reference.ReferenceParams.CreateDateTime),
          refNumber: reference.ReferenceParams.RefNumber,
        },
        refImageUrl: reference.RefImageUrl,
        isAutogenRef: reference.IsAutogenRef,
        isChatOnly: reference.IsChatOnly,
        productType: reference.ProductType,
        productTag: reference.ProductTag,
        refHeadlineName: reference.RefHeadlineName,
      })),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
